<template>
    <uw-content title="产品管理">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-input prefix-icon="el-icon-search" v-model="search.name" @input="$lodash.debounce(productSearch, 500)" size="mini" placeholder="产品名称检索" :style="{ marginRight: '10px' }"></el-input>
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="$refs.productCreate.open({ type_id: search.type_id })" >新的产品</el-button>
        </template>

        <div class="w-height-auto">

            <!-- 产品类别 -->
            <uw-scrollbar style="width: 250px; box-sizing: border-box; padding-right: 10px; float: left; margin-right: 10px;">
                <el-tree
                    ref="productType"
                    node-key="id"
                    @node-click="productTypeClick"
                    :data="productType.data"
                    :props="productType.props"
                    :default-expanded-keys="productType.defaultExpandedKey"
                    :current-node-key="productType.currentNodeKey">
                    <span slot-scope="{ data, node }" class="el-tree-custom-node">
                        <span>{{ data.name }}</span>
                        <span v-if="node.isCurrent">
                            <!-- 下拉菜单 -->
                            <el-dropdown trigger="click" size="mini" @click.native.stop>
                                <i class="el-icon-setting"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native.stop="$refs.productTypeCreate.Open({ parent: data.id })">添加子类</el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.productTypeUpdate.Open(data.id)">编辑类别</el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="productTypeDelete(data.id)" divided>移除类别</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                    </span>
                </el-tree>
            </uw-scrollbar>

            <!-- 产品列表 -->
            <div style="width: calc(100% - 260px); float: left; height: 100%;">
                <vxe-table border ref="xTable" :data="table" :loading="loading" :export-config="{}" :print-config="{}" height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 供应商 -->
                    <vxe-column min-width="220" field="supplier.name" title="供应商">
                        <template #default="{ row }">
                            <el-link icon="el-icon-office-building" @click="$refs.supplierDetails.Open(row.supplier.id)" type="primary">{{ row.supplier?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 产品名称 -->
                    <vxe-column min-width="250" field="name" title="产品名称">
                        <template #default="{ row }">
                            <el-link icon="el-icon-present" @click="$refs.productDetails.Open(row.id)" type="primary">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 产品类别 -->
                    <vxe-column min-width="160" field="type.name" title="产品类别"></vxe-column>

                    <!-- 产品品牌 -->
                    <vxe-column min-width="160" field="brand" title="产品品牌"></vxe-column>

                    <!-- 计量单位 -->
                    <vxe-column min-width="80" field="unit" title="计量单位"></vxe-column>
                    
                    <!-- 规格参数 -->
                    <vxe-column min-width="250" field="parameter" title="规格参数">
                        <template #default="{ row }">
                            {{ row.parameter?.toString() }}
                        </template>
                    </vxe-column>

                    <!-- 备注说明 -->
                    <vxe-column min-width="220" field="describe" title="备注说明"></vxe-column>

                </vxe-table>
            </div>

        </div>

        <ims-product-type-create ref="productTypeCreate" @onChange="productTypeThee" />
        <ims-product-type-update ref="productTypeUpdate" @onChange="productTypeThee" />
        <ims-product-create ref="productCreate" @onChange="productSearch" />
        <ims-product-drawer ref="productDetails" @onChange="productSearch" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="productSearch()" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            productType: {
                data: [],
                defaultExpandedKey: [1],
                currentNodeKey: 1,
                props: {
                    children: 'children',
                    label: 'name'
                }
            },

            table: [],
            search: {
                type_id: null,
                name: null
            }
        }
    },

    mounted () {
        this.productTypeThee()
        this.productSearch()
    },

    methods: {
        // 产品 - 查询
        productSearch() {
            this.loading = true
            this.$http.post('/9api/ims/product/search-all', { search: this.search, append: ['type', 'supplier'] }).then((rps) => {
                this.table = rps.data
                this.loading = false
            })
        },

        // 产品类型 - 选择
        productTypeClick(data, node) {
            this.productType.defaultExpandedKey = [data.id]
            this.productType.currentNodeKey = data.id
            this.search.type_id = data.id
            this.productSearch()
        },

        // 产品类型 - 查询（树结构）
        productTypeThee () {
            this.$http.post('/9api/ims/product-type/search-tree').then(rps => {
                this.productType.data = rps.data.tree
            })
        },

        // 产品类型 - 移除
        productTypeDelete (id) {
            this.$http.post('/9api/ims/product-type/delete', {id: id}).then(rps => {
                this.productTypeThee()
            })
        }
    }
}
</script>